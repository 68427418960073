import React from "react"

import Layout from "../components/Layout"
import FullContainer from "../components/common/FullContainer"
import styled from "styled-components"

const PlatformContainer = styled.div`
  a {
    cursor: pointer;
    color: blue;
  }

  ul {
    list-style: none;
  }
`

const Platforms = () => {
  return (
    <>
      <Layout>
        <FullContainer>
          <PlatformContainer>
            <h2>Top learning platforms:</h2>
            <ul>
              <li>
                <a href="https://www.pluralsight.com/">Pluralsight</a>
              </li>
              <li>
                <a href="https://www.udemy.com/">Udemy</a>
              </li>
              <li>
                <a href="https://www.coursera.org/">Coursera</a>
              </li>
              <li>
                <a href="https://zerotomastery.io/">Zero To Mastery</a>
              </li>
            </ul>
          </PlatformContainer>
        </FullContainer>
      </Layout>
    </>
  )
}

export default Platforms
